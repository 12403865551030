import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

/* Página para mostrar un mensaje al entrar en la dirección /wp-admin */

class ErrorCuatro extends React.Component {
  render() {
    const titulodelsitio = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const yo = get(this, 'props.data.site.siteMetadata.image')
    const url = get(this, 'props.data.site.siteMetadata.url')
    const descripcion = get(this, 'props.data.site.siteMetadata.description')

    return (
      <Layout location={this.props.location}>
        <Helmet title={titulodelsitio}>
          <meta name="description" content={descripcion} />
          <meta name="image" content={yo} />
          <link rel="canonical" href={url} />
        </Helmet>
        <section className="bg-white p-6">
          <div className="px-2">
            <h1 className="bg-blue-400 pattern text-2xl font-semibold text-white mb-6 py-3 mx-auto text-center">
              <strong>ERROR 404 </strong> - ! Página no encontrada !
            </h1>
            <p className="text-gray-800 mt-4 text-center">
              ¿Enserio pensabas que iba a usar Wordpress? :-P
            </p>
            <div className="flex flex-col items-center justify-center mt-6">
              {posts.map(({ node }) => {
                return <ArticlePreview article={node} />
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ErrorCuatro

/**
 * Consulta GraphQL para obtener los datos de la página
 * allContentfulBlogPost: consulta para obtener los datos de las entradas. Esta consulta es propia del plugin de Contentful
 */

export const pageQuery = graphql`
         query noEncontradaQueryDos {
           site {
             siteMetadata {
               title
               description
               url
             }
           }
           allContentfulBlogPost(
             sort: { fields: [publishDate], order: DESC }
             limit: 3
           ) {
             edges {
               node {
                 title
                 slug
                 publishDate(
                   formatString: "D [de] MMMM [del] YYYY"
                   locale: "es-es"
                 )
                 }
               }
             }
           }
       `
