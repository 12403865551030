import React from 'react'
import { Link } from 'gatsby'

/* Componente para crear el link de cada artículo en la página de inicio
  article.title: Título del artículo
  article.slug: Slug del artículo (URL)
  article.publishDate: Fecha de publicación del artículo
 */

export default ({ article }) => (

  <Link className="mt-8 max-w-2xl w-full block bg-white shadow-md rounded-md border-t-4 border-blue-600 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" to={`/entradas/${article.slug}`}> <div className="flex items-center justify-between px-4 py-2">
  <h3 className="text-lg font-medium text-gray-700">{article.title}</h3>
  <span className="block text-gray-600 font-light text-sm">{article.publishDate}</span>
  </div> </Link>
)
